$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #373a3c !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #2780e3 !default;
$indigo: #014575 !default;
$purple: #613d7c !default;
$pink: #e83e8c !default;
$red: #920000 !default;
$yellow: #f0ad4e !default;
$orange: #ff7518 !default;
$green: #3fb618 !default;
$teal: #20c997 !default;
$cyan: #9954bb !default;

// Default variable overrides here
$kite-blue: #1560b7 !default;

$theme-colors: (
  'primary': $kite-blue,
  'secondary': $gray-800,
  'success': $purple,
  'info': $cyan,
  'warning': $yellow,
  'danger': $red,
  'light': $gray-300,
  'dark': $gray-800

) !default;

$accordion-button-bg: $kite-blue;
$accordion-button-active-bg: $kite-blue;
$accordion-button-color: white;
$accordion-button-active-color: white;
$accordion-border-radius: 0px;
$breadcrumb-divider: quote('>');

$header-height: 172px !default;
